<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Home" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title *"
                  v-model="title"
                  :error="!!errors.home_title"
                  :error-messages="errors.home_title"
                  @input="delete errors.home_title"
                />
                <va-input
                  label="Activity Title *"
                  v-model="activityTitle"
                  :error="!!errors.home_activity_title"
                  :error-messages="errors.home_activity_title"
                  @input="delete errors.home_activity_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Activity Description *</span>
                  <ckeditor v-model="activityDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.home_activity_description" style="color: red;">{{ errors.home_activity_description[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Activity Content *</span>
                  <ckeditor v-model="activityContent" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.home_activity_content" style="color: red;">{{ errors.home_activity_content[0] }}</div>
                </div>
                <va-toggle small label="Show Testimonials" v-model="showTestimonials"></va-toggle>
                <va-toggle small label="Show Partners" v-model="showPartners"></va-toggle>
                <va-input
                  label="SEO Title"
                  v-model="seoTitle"
                  :error="!!errors.home_seo_title"
                  :error-messages="errors.home_seo_title"
                  @input="delete errors.home_seo_title"
                />
                <va-input
                  label="SEO Description"
                  type="textarea"
                  v-model="seoDescription"
                  :error="!!errors.home_seo_description"
                  :error-messages="errors.home_seo_description"
                  @input="delete errors.home_seo_description"
                />
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                  :error="!!errors.home_seo_ogtitle"
                  :error-messages="errors.home_seo_ogtitle"
                  @input="delete errors.home_seo_ogtitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                  :error="!!errors.home_seo_ogdescription"
                  :error-messages="errors.home_seo_ogdescription"
                  @input="delete errors.home_seo_ogdescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId, 'home_seo_ogimage') ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.home_seo_ogimage"
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('home_seo_ogimage', 'home.seo.ogimage', seoOpenGraphImage[0])">Завантажити</va-button>
                  <div v-if="errors.home_seo_ogimage" style="color: red;">{{ errors.home_seo_ogimage[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      title: '',
      activityTitle: '',
      activityDescription: '',
      activityContent: '',
      showTestimonials: false,
      showPartners: false,

      seoTitle: '',
      seoDescription: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        home_title: this.title,
        home_activity_title: this.activityTitle,
        home_activity_description: this.activityDescription,
        home_activity_content: this.activityContent,
        home_testimonials_show: this.showTestimonials ? 1 : 0,
        home_partners_show: this.showPartners ? 1 : 0,

        home_seo_title: this.seoTitle,
        home_seo_description: this.seoDescription,
        home_seo_ogtitle: this.seoOpenGraphTitle,
        home_seo_ogdescription: this.seoOpenGraphDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'home') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/9?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/9/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/9?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/9?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/9?lang=${this.locale}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
        },
      })
        .then(response => {
          this.title = response.data.home_title
          this.activityTitle = response.data.home_activity_title
          this.activityDescription = response.data.home_activity_description
          this.activityContent = response.data.home_activity_content
          this.showTestimonials = !!response.data.home_testimonials_show
          this.showPartners = !!response.data.home_partners_show

          this.seoTitle = response.data.home_seo_title
          this.seoDescription = response.data.home_seo_description
          this.seoOpenGraphTitle = response.data.home_seo_ogtitle
          this.seoOpenGraphDescription = response.data.home_seo_ogdescription
          this.seoOpenGraphImageExisting = response.data.home_seo_ogimage ? process.env.VUE_APP_AGRO_ADMIN + response.data.home_seo_ogimage : null
          this.seoOpenGraphImageId = response.data.home_seo_ogimage_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
